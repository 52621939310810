/* 

1. Add your custom Css styles below
2. Place the this code in your template: 

 <link href="css/custom.css" rel="stylesheet">

*/


#thank-you-message {
    display: none;
}

#thank-you-message.show {
    display: block;
}

/* Contact page banner styling */
.contact-banner.fullscreen {
    height: 10vh !important; /* Half of viewport height */
}

.contact-banner .container-fullscreen {
    min-height: 50vh !important;
}

/* Adjust vertical alignment for smaller banner */
.contact-banner .text-middle {
    padding: 40px 0;
}
